

.description-style {
  width: 500px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-style {
  width: 500px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}