.ant-layout {
  height: 100%;
  position: relative;

  .menu-logo {
    width: 200px;
    height: 64px;
    padding: 0 26px;
    background-color: $secondary-color;
  }

  .menu-collapsed-logo {
    width: 80px;
    height: 64px;
    padding: 0 31px;
    background: $secondary-color;
  }

  .ant-layout-header {
    background: $secondary-color;
  }
  
  .ant-layout-slider {
    background: $white
  }

  .ant-layout-content {
    max-height: calc(100vh - 10px);
    overflow-y: auto;
  }
  
  .navbar-header {
    padding: 0;

    .navbar-header-wrapper {
      display: flex;
      position: relative;
      align-items: center;
    }

    .navbar-header-spacer {
      flex: 1 1 0%;
    }

    .navbar-header-dropdown {
      margin: 0 25px;
    }
  }

  .dashboard-container {
    .menu-card-items {
      border-radius: 5px;
      padding: 8px;
      align-items: center;
      justify-content: center;
      .ant-card-body {
        text-align: center;

        span {
          font-size: 25px;
          color: $primary-color;
        }

        h5 {
          margin-bottom: 0px !important;
        }
      }
    }
  }

  .table-export-button {
    a {
      color: rgba(0, 0, 0, 0.85);
      margin-left: 7px;
      float: right;
    }
  }

  .payment-method-wrapper {
    img {
      filter: brightness(0) saturate(100%) invert(35%) sepia(70%) saturate(963%) hue-rotate(198deg) brightness(95%) contrast(72%);
    }
  }
}