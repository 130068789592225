
.store-container {
  .add-store-wrapper {
    h5 {
        margin-left: 110px !important;
      }
      
    .store-price-form-item {
      .ant-row .ant-form-item {
        margin-bottom: 10px;
      }

      .ant-btn {
        margin-left: 110px !important;
      }

    }
    .price-form-item-wrapper {
      margin-bottom: 8px;
      margin-left: 110px !important;
    }

    .store-remove-icon {
      padding-top: 5px;
    }

    .add-store-submit-btn {
      margin-left: 110px;
      margin-top: 50px;
    }
  }

  .store-wrapper {
    .view-store-wrapper {
      margin: 24px 0px;
      width: 100%;
    }

    .edit-store-container {
      margin: 24px 0px;
      width: 100%;
    }

    .store-price-form-item {
      .ant-row .ant-form-item {
        margin-bottom: 10px;
      }
    }

    .price-form-item-wrapper {
      margin-bottom: 8px;
    }

    .ant-typography {
        line-height: 3;
      }
  }

  .store-action-buttons {
    margin-top: 50px;
  }

  .store-remove-icon {
    padding-top: 5px;
  }

  .store-cashback-rules {
    display: none;
  }
}