.transactions-container {
  .transactions-wrapper {

    .transaction-filter {
      .ant-col {
        margin-top: 5px;
        margin-bottom: 5px;
      }
      .ant-picker-range {
        width: 100%;
      }
    }
    input {
      width: 100%;
    }
  }
  .transaction-month-selector {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .transactions-card {
    .ant-card-body {
      padding: 12px 18px !important;
    }
    .transaction-cahshback-card {
      .ant-card-body {
        padding: 10px !important;
      }
    }
    .ant-statistic-title {
      margin-bottom: 0px;
    }
    .ant-statistic-content {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .transactions-container {
    .clear-btn {
      width: 50% !important;
    }
   .transaction-month-selector {
      .ant-picker {
        width: calc(50% - 8px) !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .transactions-container {
    .clear-btn {
      width: 100% !important;
    }
   .transaction-month-selector {
      margin-left: 0px;
      .ant-picker {
        width: 100% !important;
        margin-top: 20px;
      }
    }
  }
}
