.category-container {
  .ant-form .ant-form-item {
    margin-bottom: 30px;
  }

  .category-spin {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
  }
}