@import 'antd/dist/antd.css';
@import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
@import './palette.scss', './layout.scss', './category.scss', './blog.scss', './stores.scss',
  './transactions.scss';
  

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  color: #7a7a7a;
  font-size: 15px;
  overflow-x: hidden;
}

.main-bg {
  // background: #ebecef;
  height: 100vh;
  // display: flex;
}
.body-wrapper {
  display: flex;
}

.main-wrapper {
  height: 100vh;
  width: 100%;
  // max-width: 700px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  margin: auto;
}

///Editor
.rdw-editor-main {
  height: 40vh;
  border: 1px solid #d9d9d9;
}
// AUTH
.login-wrapper {
  height: 100vh;
  width: 100%;
  background-color: $primary-color;
  .login-container {
    position: relative;
    top: 40%;
    transform: perspective(1px) translateY(-50%);
    margin: 0 auto;
    width: calc(100% / 1.1);
    height: calc(100% / 2);
    max-width: 500px;
    border-radius: 10px;
    .login-header {
      display: flex;
      padding: 25px 25px 0;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 80px;
      h1 {
        font-size: 28px;
        font-weight: bold;
        color: $yellow;
        @media (max-width: 500px) {
          font-size: 20px;
        }
      }
      // .login-logo {
      //   width: 150px;
      //   height: 150px;
      //   @media (max-width: 500px) {
      //     width: 100px;
      //     height: 100px;
      //   }
      // }
    }
    .input-field-container {
      padding: 10px;
      margin: 5px 0;
      .auth-input-field {
        border-radius: 5px;
      }
      .auth-input-field:focus, .auth-input-field:hover {
        border-color: $yellow;
        box-shadow: 0 0 0 .2rem $yellow !important;
      }
      &.with-margins {
        padding: 10px;
      }
      label {
        color: $default-color;
        font-size: 14px;
        margin: 5px;
        button {
          text-decoration: underline;
        }
      }
    }
    .button-container {
      align-items: center;
      padding: 10px;
      margin-top: 20px;
      button {
        background-color: $yellow;
        color: white;
        font-weight: bold
      }
    }

    .error-text {
      color: red;
      margin: 15px;
    }
  }
}